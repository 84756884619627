'use client'
import { useRouter } from 'next/navigation'
import React from 'react'

export const CloseButton = () => {
    const router = useRouter()
  return (
    <button
        className="absolute top-4 right-4 text-gray-900 hover:text-gray-600 focus:outline-none"
        onClick={() => router.back()}
    >
        <span className="text-4xl">&times;</span>
    </button>
  )
}
