import { cn } from '@/lib/utils'
import { Button, Paragraph, Title } from '@umahealth/occipital'
import classNames from 'classnames'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'

export interface IModalSheetProps {
	show: boolean,
	step: number,
	texts: {
		title: string,
		primaryButton?: string,
		secondaryButton?: string,
		subtitle?: string,
	},
	actions?: {
		primary: () => void
		secondary?: () => void
	},
	children?: JSX.Element | null,
	closeOnClickOutside?: boolean,
	disabled?: {
		primary?: boolean,
		secondary?: boolean,
	},
	height?: number,
	heightWithChildren?: number,
	loading?: {
		primary?: boolean,
		secondary?: boolean,
	},
	setState?: Dispatch<SetStateAction<IModalSheetProps>>
	subtitleCenter?: boolean,
	onClose?: () => void
}

export const initialModalSheetProps: IModalSheetProps = {
	actions: {
		primary: () => {},
		secondary: () => {},
	},
	show: false,
	step: -1,
	texts: {
		primaryButton: '',
		title: '',
		secondaryButton: '',
		subtitle: '',
	},
	subtitleCenter: true,
}

/**
 * ModalSheet Component
 *
 * This component represents a modal sheet that slides up from the bottom of the screen. It can be used to display
 * information and provide actions via buttons. The modal supports dragging to close and can be customized with
 * children content, button actions, and loading states.
 *
 * @param {IModalSheetProps} props - The props used to configure the modal sheet.
 * @returns {JSX.Element} The modal sheet component.
 *
 * @example
 * <ModalSheet
 *   show={true}
 *   step={1}
 *   texts={{
 *     title: 'Example Modal',
 *     primaryButton: 'Confirm',
 *     secondaryButton: 'Cancel'
 *   }}
 *   actions={{
 *     primary: () => console.log('Primary action'),
 *     secondary: () => console.log('Secondary action')
 *   }}
 *   closeOnClickOutside={true}
 *   height={400}
 *   heightWithChildren={450}
 * />
 */
const ModalSheet = ({
	actions,
	loading,
	texts,
	show,
	children,
	closeOnClickOutside = true,
	height = 333,
	heightWithChildren = 376,
	setState,
	disabled,
	subtitleCenter,
	onClose,
}: IModalSheetProps) => {
	const [showModal, setShowModal] = useState(false)
	const heightToUse = children ? heightWithChildren : height
	const [position, setPosition] = useState(-heightToUse)
	const [isDragging, setIsDragging] = useState(false)
	const startY = useRef(0)
	const initialPosition = useRef(0)

	useEffect(() => {
		const handleMouseMove = (event: MouseEvent) => {
			if (isDragging) {
				const newPosition = initialPosition.current - (event.clientY - startY.current)
				setPosition(newPosition < 0 ? newPosition : 0)
			}
		}
		const handleTouchMove = (event: TouchEvent) => {
			if (isDragging && event.touches.length === 1) {
				const newPosition = initialPosition.current - (event.touches[0].clientY - startY.current)
				setPosition(newPosition < 0 ? newPosition : 0)
			}
		}

		const handleEnd = () => {
			setIsDragging(false)
			if (position > -(heightToUse / 2)) {
				setPosition(0)
			} else {
				if (!!setState) {
					setState(initialModalSheetProps)
				}
				if (!!onClose){
					onClose()
				}
			}
		}

		if (isDragging) {
			document.addEventListener('mousemove', handleMouseMove)
			document.addEventListener('mouseup', handleEnd)
			document.addEventListener('touchmove', handleTouchMove)
			document.addEventListener('touchend', handleEnd)
		} else {
			document.removeEventListener('mousemove', handleMouseMove)
			document.removeEventListener('mouseup', handleEnd)
			document.removeEventListener('touchmove', handleTouchMove)
			document.removeEventListener('touchend', handleEnd)
		}

		return () => {
			document.removeEventListener('mousemove', handleMouseMove)
			document.removeEventListener('mouseup', handleEnd)
			document.removeEventListener('touchmove', handleTouchMove)
			document.removeEventListener('touchend', handleEnd)
		}
	}, [isDragging, position, heightToUse])

	useEffect(() => {
		setShowModal(show)
		setPosition(show ? 0 : -heightToUse)
	}, [show])

	const handleStart = (event: React.MouseEvent | React.TouchEvent) => {
		setIsDragging(true)
		startY.current = 'touches' in event ? event.touches[0].clientY : event.clientY
		initialPosition.current = position
	}

	return (
		<div className={cn('fixed h-screen w-full left-0 bottom-0 z-[20]', {'pointer-events-none': !showModal})}>
			<div
				className={classNames('absolute inset-0 h-full w-full flex items-end overflow-hidden bg-black/25', showModal ? 'z-[51] opacity-100' : 'z-[-1] opacity-0')}
				onClick={() => {
					if (closeOnClickOutside && setState) {
						setState(initialModalSheetProps)
						if (onClose){
							onClose()
						}
					}
					setShowModal(false)
				}}
			/>
			<div
				className={classNames(
					'flex flex-col items-center bg-white rounded-t-xl w-full max-w-[600px] absolute z-[52] left-1/2 -translate-x-1/2',
					showModal ? 'opacity-100' : 'opacity-0',
				)}
				style={{
					height: `${heightToUse}px`,
					bottom: `${position}px`,
					transition: isDragging ? 'none' : 'all 0.5s ease',
					justifyContent: !children ? 'space-between' : ''
				}}
				onMouseDown={(e) => e.stopPropagation()}
			>
				<div className='py-4 mt-2 md:mt-4 mb-2 w-full flex justify-center cursor-pointer' onMouseDown={handleStart}>
					<div className="h-1 w-10 bg-[#D9D9D9] rounded-full"></div>
				</div>
				<div className='overflow-y-auto px-6 h-full flex flex-col items-center w-full'>
					<div className='flex flex-col items-center w-full'>
						<Title hierarchy="h1" color="text-grey-1" weight="font-bold" className="text-center mb-2 text-lg md:text-[20px]">
							{texts.title}
						</Title>
						<Paragraph color="text-grey-2" weight="font-regular" className={`${subtitleCenter ? 'text-center' : ''} text-balance text-sm md:text-base`} style={{ whiteSpace: 'pre-line' }}>
							{texts.subtitle && <ReactMarkdown>{texts.subtitle}</ReactMarkdown>}
						</Paragraph>
					</div>
					{children}
				</div>
				{texts.primaryButton && (
					<div className={classNames('flex flex-col items-center w-full mb-10 md:mb-14 px-6', children ? 'mt-5' : 'mt-8')}>
						<Button
							variant="filled"
							type="button"
							className="mb-1 xs:w-[87%] w-full"
							onClick={actions?.primary}
							loading={loading?.primary}
							loadingText=''
							disabled={disabled?.primary}
							data-test-id='modalButton-primary'
						>
							{texts.primaryButton}
						</Button>
						{texts.secondaryButton && (
							<Button
								variant="text"
								type="button"
								className="xs:w-[87%] w-full"
								onClick={actions?.secondary}
								loading={loading?.secondary}
								loadingText=''
								disabled={disabled?.secondary}
								data-test-id='modalButton-secondary'
							>
								{texts.secondaryButton}
							</Button>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

export default ModalSheet
