import { Dispatch, SetStateAction, useState } from 'react'
import ModalSheet, { IModalSheetProps, initialModalSheetProps } from '@/storybook/components/clientComponents/ModalSheet/ModalSheet'
import { modalSheetContent } from '../utils/steps'
import CodeInput from './CodeInput/CodeInput'
import { useSendPhoneCode } from '@/services/hooks/requests/Patient/useSendPhoneCode'
import { useValidatePhone } from '@/services/hooks/requests/Patient/useValidatePhone'
import { FieldValues, Path, UseFormReturn } from 'react-hook-form'
import { openChatBot } from '@/components/Utils/openChatBot'
import { wsPatternErrorMsg } from '@/components/Utils/validationErrorsMsg'
import { AxiosError } from 'axios'

type ExtendedFieldValues = {
	validationPhoneCode: number,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[x: string]: any,
}

interface IWsModalProps<T extends FieldValues = ExtendedFieldValues> {
	formReturn: UseFormReturn<T>,
	onSubmit: (data: T) => void,
	setStatusError?: Dispatch<SetStateAction<AxiosError>>
}

function useWsModalValidation<T extends FieldValues = ExtendedFieldValues> ({
	formReturn,
	onSubmit,
	setStatusError,
}: IWsModalProps<T>) {
	const {
		setError,
		clearErrors,
		getValues,
		setValue,
		formState: { errors },
	} = formReturn
	const [modalSheetProps, setModalSheetProps] = useState(initialModalSheetProps)
	const [modalHeightWithChildren, setModalHeightWithChildren] = useState(423)
	const [codeSendingCounter, setCodeSendingCounter] = useState(0)

	const sendPhoneValidationCode = useSendPhoneCode({
		onError: (err) => {
			setCodeSendingCounter((prev) => (prev + 1))
			const errorMsg = err.response?.data?.['exception']?.message as string
			if (errorMsg.includes('fails to match the required pattern')) {
				setError('ws' as Path<T>, {type: 'custom', message: wsPatternErrorMsg})
				setModalSheetProps(initialModalSheetProps)
			}
			setStatusError && setStatusError(err)
		},
		onSuccess: () => {
			setCodeSendingCounter((prev) => (prev + 1))
			setModalSheetProps((prevState) => openModalWs(prevState, 1))
		}
	})
	const validatePhoneCode = useValidatePhone({
		onSuccess: () => {
			setModalSheetProps(initialModalSheetProps)
			clearErrors('ws' as Path<T>)
			localStorage.setItem('phoneValidated', 'true')
			localStorage.setItem('ws', getValues('ws' as Path<T>))
			onSubmit(getValues())
		},
		onError: () => {
			setError('validationPhoneCode' as Path<T>, { type: 'custom', message: 'El código es incorrecto' })
		}
	})

	const openModalWs = (prevState: IModalSheetProps, step: number, isError?: boolean): IModalSheetProps => {
		// props para el paso 1 del proceso de validacion
		if (step === 1) {
			return {
				...prevState,
				step: 1,
				actions: {
					primary: () => {
						// validamos el codigo enviado con el ingresado
						validatePhoneCode.mutate({
							currentPhone: getValues('ws' as Path<T>),
							code: getValues('validationPhoneCode' as Path<T>),
						})
					},
					secondary: () => {
						if (codeSendingCounter >= 2) {
							// boton para saltear la validacion en caso de que tengan problemas
							setModalSheetProps(initialModalSheetProps)
							onSubmit(getValues())
						}
						// boton de reenvio del codigo
						sendPhoneValidationCode.mutate(getValues('ws' as Path<T>))
					},
				},
				texts: {
					...prevState.texts,
					primaryButton: modalSheetContent.ws.primaryButton[1],
					secondaryButton: codeSendingCounter >= 2
						? modalSheetContent.ws.secondaryButton[2]
						: modalSheetContent.ws.secondaryButton[1],
					subtitle: modalSheetContent.ws.subtitle[2],
				},
			}
		}
		// props para el paso 0 del proceso de validacion
		return {
			...prevState,
			actions: {
				primary: () => {
					// enviamos el codigo de validacion al telefono ingresado
					sendPhoneValidationCode.mutate(getValues('ws' as Path<T>))
				},
				secondary: () => {
					openChatBot()
				},
			},
			step: 0,
			texts: {
				primaryButton: modalSheetContent.ws.primaryButton[0],
				secondaryButton: modalSheetContent.ws.secondaryButton[0],
				title: modalSheetContent.ws.title,
				subtitle: isError ? modalSheetContent.ws.subtitle[0] : modalSheetContent.ws.subtitle[1],
			},
			show: true,
		}
	}

	const closeModalWs = () => {
		setModalSheetProps(initialModalSheetProps)
	}

	const isModalSheetLoading = {
		primary: () => {
			// step=1 es el paso donde solamente esta el input de telefono
			if (modalSheetProps.step === 0) return sendPhoneValidationCode.isLoading
			return validatePhoneCode.isLoading
		},
		secondary: () => {
			if (modalSheetProps.step === 1) return sendPhoneValidationCode.isLoading
		}
	}

	const modalWsComponent = (
		<ModalSheet
			{...modalSheetProps}
			setState={setModalSheetProps}
			heightWithChildren={modalHeightWithChildren}
			loading={{
				primary: isModalSheetLoading.primary(),
				secondary: isModalSheetLoading.secondary(),
			}}
		>
			{modalSheetProps.step === 1 &&
        modalSheetProps.texts.subtitle === modalSheetContent.ws.subtitle[2] ? (
					<CodeInput<T>
						setValue={setValue}
						error={errors.validationPhoneCode}
						setModalHeightWithChildren={setModalHeightWithChildren}
					/>
				) : null}
		</ModalSheet>
	)

	return {
		modalWsComponent,
		modalSheetProps,
		setModalSheetProps,
		openModalWs,
		closeModalWs,
	}
}

export default useWsModalValidation