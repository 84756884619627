interface IEntidad {
    CATALOG_KEY: number;
    ENTIDAD_FEDERATIVA: string;
    ABREVIATURA: string;
}

interface ICurpValidationValues {
    sex?: string;
    dob?: string;
    firstname?: string;
    firstlastname?: string;
    secondlastname?: string;
}

const entidades: IEntidad[] = [
    {
        "CATALOG_KEY": 0,
        "ENTIDAD_FEDERATIVA": "NO ESPECIFICADO",
        "ABREVIATURA": "NE"
    },
    {
        "CATALOG_KEY": 1,
        "ENTIDAD_FEDERATIVA": "AGUASCALIENTES",
        "ABREVIATURA": "AS"
    },
    {
        "CATALOG_KEY": 2,
        "ENTIDAD_FEDERATIVA": "BAJA CALIFORNIA",
        "ABREVIATURA": "BC"
    },
    {
        "CATALOG_KEY": 3,
        "ENTIDAD_FEDERATIVA": "BAJA CALIFORNIA SUR",
        "ABREVIATURA": "BS"
    },
    {
        "CATALOG_KEY": 4,
        "ENTIDAD_FEDERATIVA": "CAMPECHE",
        "ABREVIATURA": "CC"
    },
    {
        "CATALOG_KEY": 5,
        "ENTIDAD_FEDERATIVA": "COAHUILA DE ZARAGOZA",
        "ABREVIATURA": "CL"
    },
    {
        "CATALOG_KEY": 6,
        "ENTIDAD_FEDERATIVA": "COLIMA",
        "ABREVIATURA": "CM"
    },
    {
        "CATALOG_KEY": 7,
        "ENTIDAD_FEDERATIVA": "CHIAPAS",
        "ABREVIATURA": "CS"
    },
    {
        "CATALOG_KEY": 8,
        "ENTIDAD_FEDERATIVA": "CHIHUAHUA",
        "ABREVIATURA": "CH"
    },
    {
        "CATALOG_KEY": 9,
        "ENTIDAD_FEDERATIVA": "CIUDAD DE MÉXICO",
        "ABREVIATURA": "DF"
    },
    {
        "CATALOG_KEY": 10,
        "ENTIDAD_FEDERATIVA": "DURANGO",
        "ABREVIATURA": "DG"
    },
    {
        "CATALOG_KEY": 11,
        "ENTIDAD_FEDERATIVA": "GUANAJUATO",
        "ABREVIATURA": "GT"
    },
    {
        "CATALOG_KEY": 12,
        "ENTIDAD_FEDERATIVA": "GUERRERO",
        "ABREVIATURA": "GR"
    },
    {
        "CATALOG_KEY": 13,
        "ENTIDAD_FEDERATIVA": "HIDALGO",
        "ABREVIATURA": "HG"
    },
    {
        "CATALOG_KEY": 14,
        "ENTIDAD_FEDERATIVA": "JALISCO",
        "ABREVIATURA": "JC"
    },
    {
        "CATALOG_KEY": 15,
        "ENTIDAD_FEDERATIVA": "MÉXICO",
        "ABREVIATURA": "MC"
    },
    {
        "CATALOG_KEY": 16,
        "ENTIDAD_FEDERATIVA": "MICHOACÁN DE OCAMPO",
        "ABREVIATURA": "MN"
    },
    {
        "CATALOG_KEY": 17,
        "ENTIDAD_FEDERATIVA": "MORELOS",
        "ABREVIATURA": "MS"
    },
    {
        "CATALOG_KEY": 18,
        "ENTIDAD_FEDERATIVA": "NAYARIT",
        "ABREVIATURA": "NT"
    },
    {
        "CATALOG_KEY": 19,
        "ENTIDAD_FEDERATIVA": "NUEVO LEÓN",
        "ABREVIATURA": "NL"
    },
    {
        "CATALOG_KEY": 20,
        "ENTIDAD_FEDERATIVA": "OAXACA",
        "ABREVIATURA": "OC"
    },
    {
        "CATALOG_KEY": 21,
        "ENTIDAD_FEDERATIVA": "PUEBLA",
        "ABREVIATURA": "PL"
    },
    {
        "CATALOG_KEY": 22,
        "ENTIDAD_FEDERATIVA": "QUERÉTARO",
        "ABREVIATURA": "QT"
    },
    {
        "CATALOG_KEY": 23,
        "ENTIDAD_FEDERATIVA": "QUINTANA ROO",
        "ABREVIATURA": "QR"
    },
    {
        "CATALOG_KEY": 24,
        "ENTIDAD_FEDERATIVA": "SAN LUIS POTOSÍ",
        "ABREVIATURA": "SP"
    },
    {
        "CATALOG_KEY": 25,
        "ENTIDAD_FEDERATIVA": "SINALOA",
        "ABREVIATURA": "SL"
    },
    {
        "CATALOG_KEY": 26,
        "ENTIDAD_FEDERATIVA": "SONORA",
        "ABREVIATURA": "SR"
    },
    {
        "CATALOG_KEY": 27,
        "ENTIDAD_FEDERATIVA": "TABASCO",
        "ABREVIATURA": "TC"
    },
    {
        "CATALOG_KEY": 28,
        "ENTIDAD_FEDERATIVA": "TAMAULIPAS",
        "ABREVIATURA": "TS"
    },
    {
        "CATALOG_KEY": 29,
        "ENTIDAD_FEDERATIVA": "TLAXCALA",
        "ABREVIATURA": "TL"
    },
    {
        "CATALOG_KEY": 30,
        "ENTIDAD_FEDERATIVA": "VERACRUZ DE IGNACIO DE LA LLAVE",
        "ABREVIATURA": "VZ"
    },
    {
        "CATALOG_KEY": 31,
        "ENTIDAD_FEDERATIVA": "YUCATÁN",
        "ABREVIATURA": "YN"
    },
    {
        "CATALOG_KEY": 32,
        "ENTIDAD_FEDERATIVA": "ZACATECAS",
        "ABREVIATURA": "ZS"
    },
    {
        "CATALOG_KEY": 88,
        "ENTIDAD_FEDERATIVA": "NO APLICA",
        "ABREVIATURA": "NA"
    },
    {
        "CATALOG_KEY": 99,
        "ENTIDAD_FEDERATIVA": "SE IGNORA",
        "ABREVIATURA": "SI"
    }
]

const validEntityCodes = entidades.map(e => e.ABREVIATURA).join('|')

const getSexoCurp = (sexo: string): string => {
    switch(sexo) {
        case 'F': return 'M'  // F = Femenino -> M en CURP
        case 'M': return 'H'  // M = Masculino -> H en CURP
        case 'X': return 'X'  // X = No binario -> X en CURP
        default: return '[HMX]' // Si no hay sexo seleccionado, acepta cualquiera
    }
}

const removeArticlesFromLastName = (lastName: string): string => {
    return lastName.replace(/\b(de la|de los|de las|de|del)\b/gi, '').trim();
};

const getFirstValidName = (fullName: string): string => {
    const commonNames = ["JOSÉ", "MARIA", "MARÍA", "MA.", "MA", "JOSE", "J.", "J", "M."];
    
    const names = fullName.trim().toUpperCase().split(/\s+/);

    // Manejar nombres con abreviaciones como "M." o "J."
    if (names[0].match(/^[A-Z]\.$/)) {
        return names[1] || names[0];
    }

    // Si el primer nombre es común, usar el segundo nombre si existe
    if (commonNames.includes(names[0]) && names.length > 1) {
        return names[1];
    }

    return names[0];
};

const getLastNameFirstInternalVowel = (lastName: string): string => {
    // Buscar la primera vocal después de la primera letra
    const vowels = lastName.slice(1).toUpperCase().match(/[AEIOUÁÉÍÓÚ]/)?.[0];
    
    const vowelMap: { [key: string]: string } = {
        'Á': 'A',
        'É': 'E',
        'Í': 'I',
        'Ó': 'O',
        'Ú': 'U'
    };

    return vowels ? 
        vowels.replace(/[ÁÉÍÓÚ]/g, match => vowelMap[match] || match) : 
        'X';
};

const validateCurpBirthDate = (curp: string, dob: string): boolean => {
    const yearPrefix = Number(curp.slice(4, 6)) < 30 ? '20' : '19';
    const curpYear = `${yearPrefix}${curp.slice(4, 6)}`;
    const curpMonth = curp.slice(6, 8);
    const curpDay = curp.slice(8, 10);
    
    // Parse YYYY-MM-DD format
    const [dobYear, dobMonth, dobDay] = dob.split('-');
    
    return dobYear === curpYear && 
           dobMonth.padStart(2, '0') === curpMonth && 
           dobDay.padStart(2, '0') === curpDay;
};

const prohibitedWords = ["BACA", "BAKA", "BUEI", "BUEY", "CACA", "CACO", "CAGA", "CAGO", "CAKA", "CAKO", "COGE", "COGI", "COJA", "COJE", "COJI", "COJO", "COLA", "CULO", "FALO", "FETO", "GETA", "GUEI", "GUEY", "JETA", "JOTO", "KACA", "KACO", "KAGA", "KAGO", "KAKA", "KAKO", "KOGE", "KOGI", "KOJA", "KOJE", "KOJI", "KOJO", "KOLA", "KULO", "LILO", "LOCA", "LOCO", "LOKA", "LOKO", "MAME", "MAMO", "MEAR", "MEAS", "MEON", "MIAR", "MION", "MOCO", "MOKO", "MULA", "MULO", "NACA", "NACO", "PEDA", "PEDO", "PENE", "PIPI", "PITO", "POPO", "PUTA", "PUTO", "QULO", "RATA", "ROBA", "ROBE", "ROBO", "RUIN", "SENO", "TETA", "VACA", "VAGA", "VAGO", "VAKA", "VUEI", "VUEY", "WUEI", "WUEY"];

const substitutions: { [key: string]: string } = {
    "BACA": "BXCA",
    "BAKA": "BXKA", 
    "BUEI": "BXEI",
    "BUEY": "BXEY",
    "CACA": "CXCA",
    "CACO": "CXCO", 
    "CAGA": "CXGA",
    "CAGO": "CXGO",
    "CAKA": "CXKA",
    "CAKO": "CXKO",
    "COGE": "CXGE",
    "COGI": "CXGI",
    "COJA": "CXJA",
    "COJE": "CXJE",
    "COJI": "CXJI",
    "COJO": "CXJO",
    "COLA": "CXLA",
    "CULO": "CXLO",
    "FALO": "FXLO",
    "FETO": "FXTO",
    "GETA": "GXTA",
    "GUEI": "GXEI",
    "GUEY": "GXEY",
    "JETA": "JXTA",
    "JOTO": "JXTO",
    "KACA": "KXCA",
    "KACO": "KXCO",
    "KAGA": "KXGA",
    "KAGO": "KXGO",
    "KAKA": "KXKA",
    "KAKO": "KXKO",
    "KOGE": "KXGE",
    "KOGI": "KXGI",
    "KOJA": "KXJA",
    "KOJE": "KXJE",
    "KOJI": "KXJI",
    "KOJO": "KXJO",
    "KOLA": "KXLA",
    "KULO": "KXLO",
    "LILO": "LXLO",
    "LOCA": "LXCA",
    "LOCO": "LXCO",
    "LOKA": "LXKA",
    "LOKO": "LXKO",
    "MAME": "MXME",
    "MAMO": "MXMO",
    "MEAR": "MXAR",
    "MEAS": "MXAS",
    "MEON": "MXON",
    "MIAR": "MXAR",
    "MION": "MXON",
    "MOCO": "MXCO",
    "MOKO": "MXKO",
    "MULA": "MXLA",
    "MULO": "MXLO",
    "NACA": "NXCA",
    "NACO": "NXCO",
    "PEDA": "PXDA",
    "PEDO": "PXDO",
    "PENE": "PXNE",
    "PIPI": "PXPI",
    "PITO": "PXTO",
    "POPO": "PXPO",
    "PUTA": "PXTA",
    "PUTO": "PXTO",
    "QULO": "QXLO",
    "RATA": "RXTA",
    "ROBA": "RXBA",
    "ROBE": "RXBE",
    "ROBO": "RXBO",
    "RUIN": "RXIN",
    "SENO": "SXNO",
    "TETA": "TXTA",
    "VACA": "VXCA",
    "VAGA": "VXGA",
    "VAGO": "VXGO",
    "VAKA": "VXKA",
    "VUEI": "VXEI",
    "VUEY": "VXEY",
    "WUEI": "WXEI",
    "WUEY": "WXEY"
};

const validateCurpNoProhibitedWords = (curp: string): { isValidWord: boolean, substitution?: string, prefix?: string } => {
    const prefix = curp.slice(0, 4);
    return {
        isValidWord: !prohibitedWords.includes(prefix),
        substitution: substitutions[prefix],
        prefix: prefix
    };
};

type ValidationResult = true | string | { isValid: true, warning?: string };

const validateCurpName = (curp: string, firstname?: string, firstlastname?: string, secondlastname?: string): ValidationResult => {
    if (!firstname || !firstlastname || !secondlastname) return true;

    const curpPrefix = curp.slice(0, 4);

    // Primero verificar si el prefijo del CURP es una sustitución válida
    const isSubstitution = Object.entries(substitutions).some(([_original, substituted]) => {
        return substituted === curpPrefix;
    });

    // Si el prefijo es una sustitución válida, aceptarlo
    if (isSubstitution) {
        return true;
    }

    // Verificar palabras prohibidas
    const { isValidWord, substitution, prefix } = validateCurpNoProhibitedWords(curp);
    
    // Si la palabra no es válida, sugerir la sustitución como warning
    if (!isValidWord) {
        return {
            isValid: true,
            warning: `Tu CURP empieza con "${prefix}". 
                     Normalmente se usa "${substitution}", pero si estás seguro que así es tu CURP oficial puedes continuar.`
        };
    }

    // Limpiar y preparar los apellidos
    const cleanFirstLastname = removeArticlesFromLastName(firstlastname.toUpperCase());
    const cleanSecondLastname = removeArticlesFromLastName(secondlastname.toUpperCase());

    // Obtener las letras esperadas para el CURP
    const expectedFirstLetter = cleanFirstLastname.charAt(0);
    const expectedFirstVowel = getLastNameFirstInternalVowel(cleanFirstLastname);
    const expectedSecondLetter = cleanSecondLastname.charAt(0);
    const expectedNameLetter = getFirstValidName(firstname.toUpperCase()).charAt(0);

    // Si es una palabra prohibida, las letras esperadas deberían formar la sustitución
    const expectedPrefix = `${expectedFirstLetter}${expectedFirstVowel}${expectedSecondLetter}${expectedNameLetter}`;
    if (prohibitedWords.includes(expectedPrefix) && substitutions[expectedPrefix]) {
        return true; // Si las letras formarían una palabra prohibida, aceptamos la sustitución
    }

    // Obtener las letras del CURP
    const curpFirstLetter = curp.charAt(0);
    const curpFirstVowel = curp.charAt(1);
    const curpSecondLetter = curp.charAt(2);
    const curpNameLetter = curp.charAt(3);

    // Validar cada letra solo si no es una sustitución válida
    if (curpFirstLetter !== expectedFirstLetter) {
        return `La primera letra del CURP debe ser ${expectedFirstLetter} (primera letra del primer apellido)`;
    }
    if (curpFirstVowel !== expectedFirstVowel) {
        return `La segunda letra del CURP debe ser ${expectedFirstVowel} (primera vocal interna del primer apellido)`;
    }
    if (curpSecondLetter !== expectedSecondLetter) {
        return `La tercera letra del CURP debe ser ${expectedSecondLetter} (primera letra del segundo apellido)`;
    }
    if (curpNameLetter !== expectedNameLetter) {
        return `La cuarta letra del CURP debe ser ${expectedNameLetter} (primera letra del nombre)`;
    }

    return true;
}

const validateCurpDate = (curp: string, dob?: string): true | string => {
    if (!dob) return true;

    if (!validateCurpBirthDate(curp, dob)) {
        const yearPrefix = Number(curp.slice(4, 6)) < 30 ? '20' : '19';
        const curpYear = `${yearPrefix}${curp.slice(4, 6)}`;
        const curpMonth = curp.slice(6, 8);
        const curpDay = curp.slice(8, 10);
        
        const [dobYear, dobMonth, dobDay] = dob.split('-');
        return `La fecha de nacimiento (${dobDay}/${dobMonth}/${dobYear}) no coincide con la fecha en el CURP (${curpDay}/${curpMonth}/${curpYear})`;
    }

    return true;
}

export const validateCurp = (curp: string, formValues?: ICurpValidationValues): ValidationResult => {
    if (!curp) return 'El CURP es requerido';

    // Curp genérica pasa tranquila
    if (curp === "XXXX999999XXXXXX99") return true
    
    // Validar el sexo
    if (formValues?.sex) {
        const sexoCurp = getSexoCurp(formValues.sex);
        if (curp.length >= 11 && curp[10] !== sexoCurp) {
            return `El sexo en el CURP (${curp[10]}) no coincide con el sexo seleccionado (${sexoCurp})`;
        }
    }

    // Validar el nombre y apellidos
    const nameValidation = validateCurpName(
        curp,
        formValues?.firstname,
        formValues?.firstlastname,
        formValues?.secondlastname
    );
    
    // Si hay un warning, propagarlo
    if (typeof nameValidation === 'object' && nameValidation.warning) {
        return nameValidation;
    }
    if (nameValidation !== true) return nameValidation;

    // Validar la fecha de nacimiento
    const dateValidation = validateCurpDate(curp, formValues?.dob);
    if (dateValidation !== true) return dateValidation;

    // Validar el formato general del CURP
    const curpRegex = new RegExp(
        '^' + // Inicio de la cadena
        '[A-Z]' + // 1. Primera letra del apellido paterno
        '[AEIOUX]' + // 2. Primera vocal del apellido paterno
        '[A-Z]' + // 3. Primera letra del apellido materno
        '[A-Z]' + // 4. Primera letra del nombre
        '\\d{2}' + // 5-6. Año de nacimiento (dos dígitos)
        '(?:0[1-9]|1[0-2])' + // 7-8. Mes de nacimiento (01-12)
        '(?:0[1-9]|[12]\\d|3[01])' + // 9-10. Día de nacimiento (01-31)
        (formValues?.sex ? getSexoCurp(formValues.sex) : '[HMX]') + // 11. Sexo
        `(?:${validEntityCodes})` + // 12-13. Entidad federativa
        '[B-DF-HJ-NP-TV-Z]{3}' + // 14-16. Consonantes internas
        '[A-Z0-9]' + // 17. Homoclave
        '\\d' + // 18. Dígito verificador
        '$' // Fin de la cadena
    );

    if (!curpRegex.test(curp)) {
        return 'El CURP no tiene un formato válido';
    }

    return true;
} 