import IFormRegister from './registerInputs'

type StepsAR = 'birth and sex' | 'dni' | 'email' | 'fullname' | 'chosenname and pronouns' | 'tel'
type StepsMX = 'personalData' | 'tel'

export interface IStep {
	step: StepsAR | StepsMX;
	inputs: Array<keyof IFormRegister>;
	title: string;
	subtitle?: string;
	/**
	 * array que indica los inputs que son opcionales en un determinado step
	 */
	optionals?: string[]
}

export const stepsMX: IStep[] = [
	// Muy importante el orden!
	{
		title: '2. Datos personales',
		subtitle: 'A continuación te solicitaremos información para generar tu cuenta en UMA.',
		step: 'personalData',
		inputs: ['firstname', 'firstlastname', 'secondlastname', 'dob', 'sex', 'curp', 'privacyCheck'],
		optionals: ['secondlastname']
	},
	{
		step: 'tel',
		inputs: ['ws'],
		title: '2. Datos personales',
		subtitle: 'A continuación te solicitaremos información para generar tu cuenta en UMA.'
	},
] as const

export const stepsAR: IStep[] = [
	// Muy importante el orden!
	{
		step: 'fullname',
		inputs: ['firstname', 'lastname', 'chosenNameCheck', 'chosenName', 'pronouns'],
		title: '2. Datos personales',
		subtitle: 'A continuación te solicitaremos información para generar tu cuenta en UMA.',
		optionals: ['chosenName', 'pronouns', 'chosenNameCheck'],
	},
	{
		step: 'tel',
		inputs: ['ws'],
		title: '2. Datos personales',
		subtitle: 'A continuación te solicitaremos información para generar tu cuenta en UMA.'
	},
	{
		step: 'birth and sex',
		inputs: ['dob', 'sex', 'dni', 'nationality', 'privacyCheck'],
		title: '3. Historia clínica',
		subtitle: 'Para generar tu historia clínica, te solicitaremos datos de tu documento de identidad.',
	},
] as const

interface IContent {
	title: string,
	subtitle: string[],
	primaryButton: string[],
	secondaryButton: string[],
}

interface IModalContent {
	ws: IContent,
	dni: IContent,
}

/** importante el orden */
export const modalSheetContent: IModalContent = {
	ws: {
		title: 'Validemos tu teléfono',
		subtitle: [
			'El número que ingresaste está asociado a una cuenta existente. Si es tuyo, vamos a hacer el proceso correspondiente de validación.',
			'Para validar tu número de contacto, te enviamos por sms un código de 6 dígitos.',
			'Para validar tu número de contacto, te enviamos por sms un código de 6 dígitos. Escríbelo a continuación.',
		],
		primaryButton: [
			'Comenzar validación',
			'Continuar',
		],
		secondaryButton: [
			'Comunicarme con soporte',
			'No recibí el código',
			'Validar más tarde',
		],
	},
	dni: {
		title: 'Validación de documento',
		subtitle: [
			'Para finalizar la creación de tu cuenta, validaremos tu documento de identidad.',
			'El número que ingresaste está asociado a una cuenta existente. Si es tuyo, vamos a hacer el proceso correspondiente de validación.',
		],
		primaryButton: [
			'Comenzar validación',
		],
		secondaryButton: [
			'Comunicarme con soporte',
		],
	}
} as const