import React, { ChangeEvent, Dispatch, MouseEvent, RefObject, useEffect, useRef, useState } from 'react'
import { inputsToMap } from './inputsToMap'
import classNames from 'classnames'
import { getLastInputEnabled } from './getLastInputEnabled'
import { DeepRequired, FieldError, FieldErrorsImpl, FieldValues, Merge, Path, PathValue, UseFormSetValue } from 'react-hook-form'

type CodeValueKeys = 'input1' | 'input2' | 'input3' | 'input4' | 'input5' | 'input6'

export type InputsRefs = Record<CodeValueKeys, RefObject<HTMLInputElement>>

interface IExtendedEventTarget extends EventTarget {
	id?: string
	value?: string
}
interface IExtendedKeyboardEvent extends KeyboardEvent {
	target: IExtendedEventTarget | null
}
interface IExtendedMouseEvent extends MouseEvent {
	target: IExtendedEventTarget
}

type ExtendedFieldValues = {
	validationPhoneCode: number,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[x: string]: any,
}
interface ICodeInputProps<T extends FieldValues = ExtendedFieldValues> {
	setValue: UseFormSetValue<T>
	error: FieldError | Merge<FieldError, FieldErrorsImpl<DeepRequired<T>[string]>> | undefined
	setModalHeightWithChildren: Dispatch<React.SetStateAction<number>>
}

/**
 * Componente para ingresar el codigo de validacion del telefono.
 * Esta compuesto por 6 inputs que actuan en conjunto como uno solo.
 * Cada input corresponde a 1 digito, ya que tienen un maxLength=1.
 * Los inputs que no tengan value estaran en modo readOnly, exceptuando aquel que este proximo a un input con value.
 * Al clickear en un input con readOnly=true, se seteara automaticamente el focus en el ultimo (de izquierda a derecha) input con readOnly=false
 * Al eliminar el value en un input, automaticamente se setea en readOnly=true y el focus pasa al input anterior.
 */
const CodeInput = <T extends FieldValues = ExtendedFieldValues>({ setValue, error, setModalHeightWithChildren }: ICodeInputProps<T>) => {
	// Aca guardamos los valores de cada input de la siguiente forma: { input1: "3", input2: "6", ... }
	const [codeValue, setCodeValue] = useState<Record<string, string>>({})

	// Guardamos una referencia a cada input para poder manipular propiedades como 'readOnly' o metodos como 'focus()'
	const inputsRefs: InputsRefs = {
		input1: useRef<HTMLInputElement>(null),
		input2: useRef<HTMLInputElement>(null),
		input3: useRef<HTMLInputElement>(null),
		input4: useRef<HTMLInputElement>(null),
		input5: useRef<HTMLInputElement>(null),
		input6: useRef<HTMLInputElement>(null),
	}

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		const actualInputValue = e.target.value
		/** Esto va a ser algo como 'string1' */
		const stringId = e.target?.id
		/** Aca obtenemos solo el numero 1 del string 'input1' */
		const numberId = Number(stringId?.slice(stringId?.length - 1))

		// si no recibimos ningun valor, quiere decir que estamos borrando
		if (!actualInputValue) {
			// entonces eliminamos el value del ultimo input
			setCodeValue((prevValue) => {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { [`input${numberId}`]: _, ...rest } = prevValue
				return rest
			})
		}
    
		// si recibimos un valor 
		if (actualInputValue.length) {
			const lastInputEnabled = getLastInputEnabled(inputsRefs)
			// si el ultimo input enabled no tiene valor, lo focuseamos
			if (!lastInputEnabled?.current?.value) {
				lastInputEnabled?.current?.focus()
			}
			// caso contrario quiere decir que estamos parados en el ultimo input enabled
			else if (inputsRefs[`input${numberId + 1}`]?.current) {
				// habilitamos el siguiente input
				inputsRefs[`input${numberId + 1}`].current.readOnly = false
				// y lo focuseamos
				inputsRefs[`input${numberId + 1}`].current.focus()
			}
			// agregamos el nuevo valor al state de react
			setCodeValue((prevValue) => ({ // { input1: '5', input2: '7', ... }
				...prevValue,
				[stringId]: actualInputValue,
			}))
		}
	}

	useEffect(() => {
		// updatear el value en react-hook-form
		const concatenatedValue = Number(Object.values(codeValue).join('')) as PathValue<T, Path<T>>
		setValue('validationPhoneCode' as Path<T>, concatenatedValue)
		/** funcion para escuchar cuando el usuario presiona la tecla de borrar mandarlo al input anterior */
		const handleKeyDown = (e: IExtendedKeyboardEvent) => {
			const inputsKeys = Object.keys(inputsRefs) // [ 'input1', 'input2', ... ]
			const stringId = e.target?.id // 'input1'
			const numberId = Number(stringId?.slice(stringId?.length - 1)) // 1

			if (numberId === 1) return // si ya estamos en el primer input, no hacemos nada

			if (
				e.key === 'Backspace' &&
        stringId &&
        inputsKeys.includes(stringId) && // esto es para trackear solo los eventos ocurridos en los inputs de este componente
        e.target?.value === ''
			) {
				// trasladamos el focus al input anterior
				inputsRefs[`input${numberId - 1}`]?.current?.focus()

				// seteamos el input actual como readOnly y actualizamos el state de react
				if (inputsRefs[stringId]?.current) {
					inputsRefs[stringId].current.readOnly = true
					setCodeValue((prevValue) => {
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						const { [stringId]: _, ...rest } = prevValue
						return rest
					})
				}
			}
		}
		document.addEventListener('keydown', handleKeyDown)

		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [codeValue])

	// seteo el onClick de los inputs que estan readOnly para que focuseen al ultimo input enabled
	const onClick = (e: IExtendedMouseEvent) => {
		const lastInputEnabled = getLastInputEnabled(inputsRefs)
		// si el input que clickeo el usuario tiene value, seteo el focus en ese mismo
		if (e.target.id && (e.target.value || !inputsRefs[e.target.id]?.current?.readOnly) ) {
			return inputsRefs[e.target.id]?.current?.focus()
		}
		lastInputEnabled?.current?.focus()
	}

	useEffect(() => {
		// si hay un error seteamos los bordes de los inputs en rojo
		if (error?.message) {
			for (const input of Object.keys(inputsRefs) as CodeValueKeys[]) {
				const inputRef = inputsRefs[input]
				if (inputRef?.current) {
					inputRef.current.style.borderColor = 'rgb(231,29,29)'
					inputRef.current.style.marginBottom = '10px'
				}
			}
			// aumento el heigth del modal para tener lugar para el mensaje del error
			setModalHeightWithChildren(457)
		}
	}, [error?.message])

	return (
		<>
			<div className='flex'>
				{inputsToMap.map(({border, id}) => {
					const numberId = Number(id.slice(id.length - 1))
					return (
						<input
							key={id}
							className={classNames(border, 'border-solid border-grey-3 text-l text-grey-2 py-2 px-[15px] font-semibold w-[47px] my-6')}
							type='text'
							inputMode='numeric'
							placeholder='0'
							maxLength={1}
							id={id}
							ref={inputsRefs[id]}
							onChange={onChange}
							value={codeValue[id]}
							readOnly={id === 'input1' ? false : !codeValue[`input${numberId - 1}`]}
							onClick={(e) => onClick(e)}
						/>
					)})}
			</div>
			{error && 
				<div className='text-error mb-6'>
					{error?.message as string}
				</div>
			}
		</>
	)
}

export default CodeInput