import Logo from '@/components/HomePage/Logo'
import { IClientState } from '@/config/clients'
import { Icon } from '@umahealth/occipital/client'
import { IHeader } from './IHeader'
import { isFarmatodo } from '@/config/endpoints'

export default function HeaderLogo({type, client} : { type: IHeader['type'], client?: IClientState|null }){
	if (type === 'back'){
		return <Icon
			name="arrowBack"
			size="size-5"
			color={isFarmatodo ? 'text-grey-50' : 'text-accent-900'}
			focusable={false}
			aria-hidden={true}

		/>
	}
	return (
		<Logo
			client={client}
			UMA_LOGO={{
				height: 48,
				width: 48,
			}}
			IOMA_LOGO={{
				className: 'h-6',
				height: 48,
				width: 32
			}}
			FARMATODO_LOGO={{
				className: 'h-6',
				height: 49,
				width: 119
			}}
		/>
	)
}
